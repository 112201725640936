<template>
  <view-wrapper title="我的关注">
    <div class="my-content">
      <e-table
        :columns="myCollect.columns"
        :data-source="myCollect.data"
        row-key="id"
        pagination
        :paging="myCollect.paging"
        :method="myCollect.get"
        :loading="myCollect.loading"
      >
        <template #name="{ text, record }">
          <a-tooltip :title="record.supplier.name || ''">
            <span>{{ text }}</span>
          </a-tooltip>
        </template>
        <template #manage="{ record }">
          <span>{{
            record.supplier.businessType.code === 'W'
              ? record.supplier.vehicleBrands.join('/')
              : myCollect.manageMessage(record.supplier)
          }}</span>
        </template>
        <template #contactPerson="{ record }">
          <span>{{ record.supplier.manager?.name }}</span>
          <a-tooltip :title="record.supplier.manager?.cellphone || ''">
            <PhoneFilled class="cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px" />
          </a-tooltip>
          <MessageFilled
            v-if="record.supplier.manager.chatUser"
            @click="webim.open(record.supplier.manager.chatUser)"
            class="cursor-pointer operation-model"
            style="color: #ffd100; margin-left: 8px"
          />
        </template>
        <template #operation="{ record }">
          <span class="operation-model">
            <a-button
              type="link"
              class="text-primary"
              :disabled="cancelLoading"
              @click="myCollect.cancelCollect.click(record)"
            >
              取消关注
            </a-button>
          </span>
        </template>
      </e-table>
    </div>
  </view-wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { formatArea, useAjax, useTable, useWebim } from '@vue-mfe/utils'

import { Tooltip as ATooltip, message } from 'ant-design-vue'
import { MessageFilled, PhoneFilled } from '@ant-design/icons-vue'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'
const columns = [
  { title: '序号', width: 80, customRender: ({ index }) => index + 1 },
  { title: '商家名称', dataIndex: 'supplier.shortName', slots: { customRender: 'name' } },
  { title: '地区', width: 200, customRender: ({ record }) => formatArea(record.supplier.area) },
  {
    title: '品牌经营信息',
    width: 300,
    ellipsis: true,
    customRender: ({ record }) =>
      record.supplier.businessType.code === 'W'
        ? record.supplier.vehicleBrands.join('/')
        : record.supplier.parts
            .concat(record.supplier.customKeywords)
            .map((item: any) => item.message || item.keyword)
            .join('/')
  },
  {
    title: '公司联系方式',
    customRender: ({ record }) => (record.supplier.manager && record.supplier.manager.cellphone) || '-'
  },
  { title: '联系人', slots: { customRender: 'contactPerson' } },
  { title: '操作', align: 'center', slots: { customRender: 'operation' } }
] as ColumnProps[]
export default defineComponent(
  {
    components: {
      MessageFilled,
      PhoneFilled,
      ATooltip
    },
    setup () {
      const webim = useWebim()
      const collectId = ref('')
      const { run: cancel, loading: cancelLoading } = useAjax(
        {
          action: 'DELETE /client/supplier/collect/:branchSupplierCollectId',
          params: () => collectId.value,
          success () {
            message.success('取关成功')
            myCollect.get()
          },
          lazy: true
        }
      )
      const myCollect = useTable(
        {
          ajax: {
            get: {
              action: 'GET /client/supplier/collect'
            }
          },
          columns,
          manageMessage (supplier: any) {
            return supplier.parts
              .concat(supplier.customKeywords)
              .map((item: any) => item.message || item.keyword)
              .join('/')
          },
          cancelCollect: {
            click (collect: any) {
              collectId.value = collect.id
              cancel()
            }
          }
        }
      )
      return {
        myCollect,
        cancelLoading,
        webim
      }
    }
  }
)
</script>

<style scoped>
.my-content {
  background-color: #fff;
  padding: 24px;
}
</style>
